import React from "react"
// import { AnimationOnScroll } from "react-animation-on-scroll"
// import Greating from "../images/csr.jpg"

export default function greatings() {
  return (
    <>
      <div className="">Greetings</div>
    </>
  )
  //       <section className="container mt-5 pt-5">
  //         <h2 className="text-center fw-bold ">
  //           Greetings from Team Indian ESG Network!
  //         </h2>
  //         {/* <h3 className="fw-bold">Greetings from Team Indian ESG Network!</h3> */}
  //         <div className="row align-items-center">
  //           <div className="col col-md-12 col-lg-5">
  //             <AnimationOnScroll
  //               animateIn="animate__fadeInLeft animate__slow"
  //               // animateOut="animate__fadeInRight"
  //             >
  //               <img src={Greating} alt={``} className="img-fluid" />
  //             </AnimationOnScroll>
  //           </div>
  //           <div className="col-md-12 col-lg-7 ">
  //             <br />

  //             <AnimationOnScroll
  //               animateIn="animate__fadeInRight animate__slow"
  //               // animateOut="animate__fadeInRight"
  //             >
  //               <div style={{ fontSize: "18px" }}>
  //                 <div>
  //                   <input type="checkbox" class="read-more-state" id="post-1" />

  //                   <div class="read-more-wrap">
  //                     <p>
  //                       We are thrilled to encourage you to join India’s largest
  //                       ESG network in order to build a larger network to interact
  //                       with as many partner organizations as possible that
  //                       interest you and your ESG goals.
  //                     </p>
  //                     <p>
  //                       We intend to provide a platform for those who are
  //                       enthusiastic about doing excellent work in the green
  //                       economy and care about people, the planet, and profits.
  //                       The ambition to contribute to the development of creative
  //                       and planet conscious human and intellectual capital for
  //                       India and the world.
  //                     </p>
  //                     <p>
  //                       The Indian ESG Network is a platform that brings together
  //                       organizations like corporations, civil society
  //                       organizations, incubators, impact assessors, social
  //                       entrepreneurs, NGOs, consulting firms, government
  //                       agencies, and many others involved in the cocreation of
  //                       sustainable economies.
  //                     </p>{" "}
  //                     <span class="read-more-target">
  //                       <p>
  //                         These events are incredible for anyone who understands
  //                         and wants to learn about the benefits of networking and
  //                         collaborating with other organizations. Losing this
  //                         opportunity to reach out to a bigger audience and meet
  //                         exceptional leaders from across the world would be a
  //                         huge loss.
  //                       </p>
  //                       <p>
  //                         This is a must-attend event that will act as a wake-up
  //                         call for future possibilities.
  //                       </p>
  //                       <p>
  //                         The Indian ESG Awards will be presented at the end of
  //                         the event, which will be organized by Management &
  //                         Entrepreneurship and Professional Skills Council (MEPSC)
  //                         and state governments. It is one of the most prestigious
  //                         awards for organizations, with the goal of recognising
  //                         and felicitating those who offer joy, pride, and glory
  //                         to the nation through their persistent commitment to
  //                         nation-building and humanity. We will be honoring and
  //                         celebrating the innovation and significant contributions
  //                         that ESG practices make to the economy and the society.
  //                         This award ceremony's major purpose would be to support
  //                         the organizations and offer them recognition.
  //                       </p>
  //                     </span>
  //                   </div>

  //                   <label
  //                     for="post-1"
  //                     class="read-more-trigger button mt-3"
  //                   ></label>
  //                 </div>
  //               </div>
  //             </AnimationOnScroll>
  //           </div>
  //         </div>
  //       </section>
}
